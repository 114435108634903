<template>
  <div class="mm-category">
    <div class="mm-category__head">
      <NuxtLink
        v-if="backButtonSlug"
        class="btn btn-secondary mm-back-button"
        :to="!manual && backButtonSlug"
        @click="manual && $emit('clickBack')"
      >
        <SvgIcon
          class="mm-back-button__icon"
          :src="EIconPath.NavigationArrowLeft20PxSvg"
        />
        Назад
      </NuxtLink>
      <div
        v-if="breadcrumbs"
        class="mm-category__breadcrumbs"
      >
        <Breadcrumbs
          :items="breadcrumbs"
          :manual="manual"
          @link-item-click="$emit('clickBreadcrumb', $event)"
        />
      </div>
      <h1>
        {{ computedPageTitle }}
        <span
          v-if="!isCatalogAvailable"
          class="count"
        >{{ availableCategoriesText }}</span>
      </h1>

      <slot name="subHeader" />
    </div>

    <div
      class="mm-category__cards"
      :class="{
        'with-margin': withMargin,
        'level-two': levelTwo,
      }"
    >
      <template v-if="category?.subcategory?.length">
        <CategoryCard
          v-for="(subcategory, i) in category?.subcategory"
          :key="i"
          class="mm-category__card"
          :category="subcategory"
          :hide-subcategories-links="isSubcategoriesLinksHidden(category, i)"
          :level-two="breadcrumbs?.length > 2"
          :supplier-id="supplierId"
          :manual="manual"
          @click="$emit('clickCategory', subcategory)"
          @click-category="$emit('clickCategory', $event)"
        />
      </template>

      <CategoryCard
        v-else
        class="mm-category__card"
        :category="category"
        :level-two="breadcrumbs?.length > 2"
        :manual="manual"
        @click="$emit('clickCategory', subcategory)"
        @click-category="$emit('clickCategory', $event)"
      />
      <Paywall
        v-if="!isCatalogAvailable"
        class="mm-category__paywall"
        :class="{
          'level-two': levelTwo,
          grid: grid,
        }"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import Breadcrumbs from 'shared/components/bars/Breadcrumbs.vue';
import CategoryCard from 'shared/components/CategoryCard.vue';
import { IBreadcrumbsItem } from 'shared/models/breadcrumbs.model';
import { ICategory } from 'shared/models/category.model';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { MAX_AVAILABLE_COUNT } from 'constants/components/categories.const';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import { useCatalog } from 'composables/useCatalog';

const props = defineProps<{
  category: ICategory;
  breadcrumbs?: Array<IBreadcrumbsItem>;
  backButtonSlug?: string;
  manual?: boolean;
  /** Оригинальное количество категорий */
  originalCount?: number;
  supplierId?: number;
  /** Принудительно включить показ саб категорий если они есть */
  isForceShowSubcategoriesLinks?: boolean;
}>();

defineEmits<{
  (e: 'clickCategory', category: ICategory): void;
  (e: 'clickBack'): void;
  (e: 'clickBreadcrumb', breadcrumb: IBreadcrumbsItem): void;
}>();

const { t } = useI18n();
const { isCatalogAvailable } = useCatalog();

/** Определяет класс для незалогированных пользователей */
const withMargin = computed(() => !isCatalogAvailable.value && props.category?.subcategory && props.category?.subcategory?.length > 3);

/** Определяет класс для незалогированных пользователей */
const levelTwo = computed(() => props.breadcrumbs && props.breadcrumbs?.length > 2);

/** Определяет класс для незалогированных пользователей */
const grid = computed(() => props.category?.subcategory && props.category?.subcategory?.length <= 3);

/** Заголовок текущей страницы */
const computedPageTitle = computed(() => {
  // Жестко проверяем на 0 чтобы выцепить самую вершину пирамиды категорий
  const isRootCategory = +props.category.id === 0;

  return props.supplierId && isRootCategory ? `Поставщик #${props.supplierId}` : props.category?.title;
});

/**Текст доступности количества категорий */
const availableCategoriesText = computed(() => {
  if (props.originalCount && !levelTwo.value) {
    return `${t(ERuLocales.CatalogCategories, props.originalCount)}`;
  } else if (!props.originalCount && levelTwo.value) {
    return `${t(ERuLocales.CatalogCategories, props.category?.originalSubcategoryCount || 0)}`;
  } else {
    return '';
  }
});

/** Проверка на необходимость скрытия ссылок субкатегорий в карточке */
function isSubcategoriesLinksHidden(category: ICategory, categoryIndex: number) {
  if (!props.isForceShowSubcategoriesLinks) {
    return isCatalogAvailable.value && category?.subcategory?.length > MAX_AVAILABLE_COUNT && categoryIndex > 5;
  }
  return false;
}
</script>

<style lang="scss">
@import 'styles/base/common/variables';

.mm-category {
  padding-bottom: 8px;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;

  .mm-back-button {
    padding: 8px 16px 8px 10px;
    margin-bottom: 20px;
    width: max-content;

    &__icon {
      width: 20px;
      margin: 0px 8px 0px 0px;
    }
  }

  &__head {
    padding: 0 40px;
    margin-bottom: 36px;

    h1 {
      font-weight: 400;
      font-size: 32px;
      color: $text-black;
    }

    .count {
      @include text-r16;

      color: $color-green-muted;
      margin-left: 8px;
    }
  }

  &__breadcrumbs {
    margin-bottom: 12px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(215px, auto);
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 8px 60px;
    position: relative;
  }

  &__paywall {
    position: absolute;
    bottom: -56px;
    width: 100%;

    &.grid {
      grid-column: 1 / span 3;
      position: static;

      &::before {
        display: none;
      }
    }
  }

  &__brands {
    padding: 0 8px;

    .mm-headline-1 {
      margin-bottom: 28px;
      padding: 0 12px;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .mm-category {
    &__head {
      padding: 0 40px !important;
    }

    &__card {
      padding: 32px !important;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .mm-category__head {
    padding: 0 32px;
  }

  .mm-category__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(215px, auto);
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 8px 60px;
  }

  .mm-category__card {
    border-right: 1px solid $category-cards-border-c;
    border-bottom: 1px solid $category-cards-border-c;
    padding: 24px;

    &:first-child:last-child {
      border-radius: 8px;
    }

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
    }

    &:nth-child(3n + 1) {
      border-left: 1px solid $category-cards-border-c;
      padding-left: 23px;
    }

    &:nth-child(3n + 3) {
      padding-right: 23px;
    }

    &:nth-child(-n + 3) {
      border-top: 1px solid $category-cards-border-c;
    }

    &:nth-child(3n):last-child,
    &:nth-child(3n):nth-last-child(2),
    &:nth-child(3n):nth-last-child(3) {
      border-bottom-right-radius: 8px;
    }

    &:nth-child(3),
    &:nth-child(2):last-child {
      border-top-right-radius: 8px;
    }

    &:nth-child(3n-2):last-child,
    &:nth-child(3n-2):nth-last-child(2),
    &:nth-child(3n-2):nth-last-child(3) {
      border-bottom-left-radius: 8px;
    }
  }

  .mm-category {
    &__cards {
      &.with-margin {
        &.level-two {
          margin-bottom: 212px;
        }
      }
    }

    &__paywall {
      &.level-two {
        bottom: -200px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .mm-category {
    .mm-back-button {
      margin-top: 13px;
    }

    &__head {
      padding: 0 20px;

      h1 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__cards {
      grid-template-columns: repeat(2, 1fr);
      margin: 0 4px 60px;
      grid-auto-rows: minmax(96px, auto);

      &.with-margin {
        margin-bottom: 108px;
      }
    }

    &__card {
      border-right: 1px solid $category-cards-border-c;
      border-bottom: 1px solid $category-cards-border-c;
      padding: 29px 0;

      &:first-child:last-child {
        border-radius: 8px;
      }

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }

      &:nth-child(2n + 1) {
        border-left: 1px solid $category-cards-border-c;
        padding-left: 16px;
        padding-right: 12px;
      }

      &:nth-child(2n + 2) {
        padding-left: 12px;
        padding-right: 16px;
      }

      &:nth-child(-n + 2) {
        border-top: 1px solid $category-cards-border-c;
      }

      &:nth-child(2n):last-child,
      &:nth-child(2n):nth-last-child(2) {
        border-bottom-right-radius: 8px;
      }

      &:nth-child(2) {
        border-top-right-radius: 8px;
      }

      &:nth-child(2n-1):last-child,
      &:nth-child(2n-1):nth-last-child(2) {
        border-bottom-left-radius: 8px;
      }
    }

    &__brands {
      .mm-headline-1 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__paywall {
      bottom: -96px;

      &.grid {
        grid-column: 1 / span 2;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-category {
    .mm-back-button {
      margin-top: 13px;
    }

    &__head {
      margin-bottom: 28px;
      padding: 0 20px;

      .mm-category__breadcrumbs {
        margin-right: -16px;
      }

      h1 {
        font-size: 20px;
        line-height: 28px;
      }

      &__cards {
        grid-auto-rows: minmax(96px, auto);
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      border: 1px solid $category-cards-border-c;
      margin: 0 4px 48px;

      &.with-margin {
        margin-bottom: 22px;
      }
    }

    &__card {
      width: 100%;
      border: none;
      padding: 20px 15px;

      &:nth-child(n + 2) {
        border-top: 1px solid $category-cards-border-c;
      }
    }

    &__brands {
      .mm-headline-1 {
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__paywall {
      bottom: -16px;
    }

    .mm-category__breadcrumbs {
      overflow: hidden;
      height: 24px;

      :deep(.mm-breadcrumbs) {
        flex-wrap: nowrap;
        overflow: auto;
        padding-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 676px) {
  .mm-category {
    &__cards {
      &.with-margin {
        margin-bottom: 76px;
      }
    }
    &__paywall {
      bottom: -70px;
    }
  }
}

@media only screen and (max-width: 379px) {
  .mm-category {
    &__cards {
      &.with-margin {
        margin-bottom: 96px;
      }
    }
    &__paywall {
      bottom: -90px;
    }
  }
}
</style>
